.area {
  width: 100%;
  // height: 1080px;
  // background: pink;
  overflow: hidden;
  position: relative;

  .desc {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    display: flex;
    align-items: center;

    text-align: center;

    .center {
      width: 100%;

      &.color {
        color: #000;

        .title {
          color: #234EB1;
        }

        .btn {
          background: #234EB1;
          cursor: pointer;
          color: #fff;
        }
      }
    }

    font-weight: 400;
    color: #FFFFFF;
    line-height: 30px;

    .title {
      font-size: 44px;
      font-weight: bold;
      margin-bottom: 40px;
    }

    .btn {
      width: 108px;
      height: 40px;
      background: #FFFFFF;
      color: #000;
      font-size: 16px;
      margin: 0 auto;
      margin-top: 40px;
      cursor: pointer;

      line-height: 40px;
    }
  }

}

.content {
  position: relative;

  img {
    display: block;
    width: 96%;
    margin: 30px auto;
    border-radius: 30px;
    height: auto;
  }

}
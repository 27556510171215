.area {
  width: 100%;
  // height: 1080px;
  // background: pink;
  position: relative;
  overflow: hidden;

  &.v2 {
    width: 90%;
    margin: 0 auto;
    .item{
      width: 45%;
      img{
        margin-bottom: 76px;
      }
      .label1{
        width: 90%;
        margin: 0 auto;
        font-size: 16px;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 28px;
        text-align: center;
        margin-bottom: 152px;
      }
    }
    &.flex{
      display: flex;
      justify-content: space-around;
    }
    .bottomLabel{
      width: 90%;
      position: absolute;
      left: 3%;
      bottom:20px;
      font-size: 16px;
      font-family: SourceHanSansCN, SourceHanSansCN;
      font-weight: 400;
      color: #000000;
      text-align: left;
      line-height: 28px;
    }
    .bottomLabel2{
      width: 80%;
      font-size: 16px;
      margin: 0 auto;
      text-align: center;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 28px;
    }
    .bottomLabel3{
      width: 80%;
      font-size: 16px;
      margin: 76px auto 158px;
      text-align: center;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 28px;
      margin-top: 76px;
    }
    .btn{
      width: 199px;
        height: 48px;
        background: #4F4F4F;
        border-radius: 31px;
        margin: 60px auto 0;
        font-size: 16px;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 48px;
        letter-spacing: 1px;
        text-align: center;
        cursor: pointer;
        .more{
          width: 35px;
          height: auto;
          margin-left: 10px;
          display: inline-block;
          vertical-align: middle;
        }
    }
  }

  .video {

    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
  }
  .desc1{
    width: 100%;
    background: #202020;
    padding:20px 0 60px;
  }
  .desc {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;


    color: #FFFFFF;
    line-height: 66px;
    text-align: center;

    &.flex {
      display: flex;
      align-items: center;

      .title1 {
        color: #0066ff;
        font-weight: bold;
        font-size: 44px;
        margin-bottom: 157px;
      }

      .label {
        width: 1123px;
        margin-top: 110px;
        height: 220px;
        font-size: 24px;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 44px;
      }
    }

    .title {
      font-size: 44px;
      margin-bottom: 57px;
      font-weight: bold;
      margin-top: 100px;
    }

    .label {
      text-align: center;
      width: 700px;
      margin: 0 auto;
      font-size: 24px;
      font-family: SourceHanSansCN, SourceHanSansCN;
      color: #FFFFFF;
      line-height: 47px;
    }
  }

}

.content {
  position: relative;
  background: #101010;

  img {
    display: block;
    width: 100%;
    height: auto;
  }

}

.boxTitle {
  font-size: 44px;
  font-family: SourceHanSansCN, SourceHanSansCN;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 53px;
  margin: 132px auto 116px;
  width: 90%;
}
.boxTitleCenter{
  text-align: center;
  font-size: 44px;
  font-family: SourceHanSansCN, SourceHanSansCN;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 53px;
  margin: 132px auto 180px;
  width: 90%;
  img
  {
    width: 107px;
    margin: 0 auto;
    margin-bottom: 75px;
    height: auto;
  }
}

.blue {
  color: #2797FF;
}
.swWrap {
  width: 100%;
  margin-top: 126px;

  // background: #0066ff;
  .container {
    width: 100%;
    margin: 0 auto;
    position: relative;
  }

  .swiperSlide {
    // position: relative;
    // left: -50%;
  }

  style.info {
    width: 820px;
    height: 84px;
    font-size: 16px;
    font-weight: 400;
    color: #000000;
    line-height: 28px;
  }

  img {
    width: 95%;
    height: auto;
    display: block;
    margin: 0 auto;
  }

  .item {
    margin: 0 auto;
    margin-top: 24px;
    text-align: center;

    .label {
      margin: 0 auto;
      margin-top: 60px;
      margin-bottom: 100px;
      width: 901px;
      height: 67px;
      font-size: 16px;
      font-weight: 400;
      color: #fff;
      line-height: 26px;

      .blue {
        color: #2797FF;
      }
    }
  }

  p {
    margin: 0;
  }

  .ptitle {
    font-size: 40px;
    font-weight: 500;
    color: #000000;
    line-height: 47px;
  }

  .pdesc {
    font-size: 14px;
    font-weight: 500;
    color: #2e3033;
    line-height: 16px;
    margin-top: 16px;
  }

  .dot,
  .span2 {
    font-size: 0;

    i {
      display: inline-block;
      width: 8px;
      height: 8px;
      background: #dce0e6;
      border-radius: 50%;
      margin-right: 5px;
    }
  }

  .dot i:nth-child(1) {
    width: 28px;
    height: 8px;
    background: #0066ff;
    border-radius: 8px;
  }

  .span2 i:nth-child(1) {
    width: 28px;
    height: 8px;
    background: #0066ff;
    border-radius: 8px;
  }
}
.model{
  display: block;
  position: fixed;
  height: 90vh;
  overflow: scroll;
  width: 96%;
  left: 3%;
  background: #000000;
  transition: all .5s;
  z-index: 100;
  border-radius: 38px;
  .close{
    position: absolute;
    top: 20px;
    right: 20px;
    width: 36px;
    height: auto;
    cursor: pointer;
  }

  /* 滚动条轨道样式 */
&::-webkit-scrollbar {
  width: 3px; /* 设置滚动条宽度 */
}
 
/* 滚动条滑块样式 */
&::-webkit-scrollbar-thumb {
  background-color: #888; /* 设置滑块背景颜色 */
  border-radius: 4px; /* 设置滑块圆角 */
}
 
/* 滚动条轨道hover状态样式 */
&::-webkit-scrollbar-track:hover {
  background-color: #000; /* 设置轨道hover状态时的背景颜色 */
}
 
/* 滚动条滑块hover状态样式 */
&::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* 设置滑块hover状态时的背景颜色 */
}
  .area{
    width: 100%;
    &.v3{
      width: 90%;
      margin-top: 151px;
    }
  }
  .title{
    width: 100%;
    padding: 227px 0;
    text-align: center;
    font-size: 44px;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 53px;
  }
  top: 120%;

  &.show{
    top: 5%;
  }

  .close{

  }
  .area1{
    width: 100%;
    // height: 1080px;
    // background: pink;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .cycleImg{
      width: 50%;
      height: auto;
      // float: left;
    }
    .text{
      text-align: center;
     
      width: 50%;
      box-sizing: border-box;
      color: #fff;
      padding: 150px;
      font-size: 16px;
      line-height: 28px;
      .title{
        font-size: 44px;
  
        font-weight: bold;
        margin-bottom: 38px;
      }
    }
  }
}

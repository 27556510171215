.area {
  width: 100%;
  // height: 1080px;
  // background: pink;
  overflow: hidden;
  position: relative;

  .desc{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    margin-left: 150px;
    font-size: 44px;

    font-weight: bold;
    color: #FFFFFF;
    line-height: 66px;
  }

}
.area1{
  width: 100%;
  // height: 1080px;
  // background: pink;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .cycleImg{
    width: 50%;
    height: auto;
    // float: left;
  }
  .text{
    text-align: center;
   
    width: 50%;

    font-size: 26px;
    .title{
      font-size: 44px;

      font-weight: bold;
      margin-bottom: 38px;
    }
  }
}
.content {
  position: relative;

  img {
    display: block;
    width: 100%;
    height: auto;
  }

}
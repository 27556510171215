.area {
  width: 100%;
  // height: 1080px;
  // background: pink;
  overflow: hidden;
  position: relative;

  .desc {
    text-align: center;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    // top: 300px;
    .title {
      font-size: 44px;
      font-weight: bold;
      color: #FFFFFF;
      line-height: 66px;
    }

    .more {
   

      cursor: pointer;
      position: relative;

      font-size: 22px;
      font-family: SourceHanSansCN, SourceHanSansCN;
      font-weight: 400;
      text-decoration: none;
      color: #FFFFFF;
 
    }
  }

  .desc1 {
    padding-left: 400px;
  }

  .desc2 {
    justify-content: flex-end;
    padding-right: 400px;
  }


  // }
 
  .bgImg {
    width: 100%;
    height: auto;
    // background: url('../../static/img/shouye-平台积分.png');
  }
}

.content {
  position: relative;

  img {
    display: block;
  }

}
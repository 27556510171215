.area {
  width: 100%;
  // height: 1080px;
  // background: pink;
  overflow: hidden;
  position: relative;

  .desc {
 
    // bottom: 0;
    // right: 0;
    // display: flex;
    // align-items: center;
    // top: 300px;
    .title {
      text-align: left;

      font-size: 44px;
      font-weight: bold;
      color: #FFFFFF;
      margin-bottom: 43px;

    }
    .info1{
      font-size: 16px;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 28px;
      width: 464px;
    }

  }

  .desc1 {
    position: absolute;
    top: 224px;
    left: 108px;
  }

  .desc2 {
    position: absolute;
    top: 79px;
    left: 108px;
    .info2{
      width: 1182px;
      height: 174px;
      font-size: 16px;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 28px;
    }
  }
  .desc3 {
    position: absolute;
    top: 244px;
    width: 100%;
    .title{
      padding-left: 108px;
    }
    // left: 108px;
    .info3{
      color: #fff;
      padding: 0 108px;
      display: flex;
      justify-content: space-between;
      .pItem{
        width: 480px;
        line-height: 30px;
        margin-bottom: 40px;
      }
      p{
        color: #31B2E4;
        line-height: 10px;
        margin: 0px 0px 10px 0px;
        
      }
    }

    
  }


  .desc4 {
    position: absolute;
    top: 130px;
    width: 570px;
    left: 108px;
    .title{
      color: #000;
    }
    .item{
      margin-bottom: 40px;
      color: #000000;
      line-height: 30px;
      .label{
        font-size: 17px;
        font-weight: bold;
      }
    }
    // left: 108px;
    // .info4{
    //   color: #fff;
    //   padding: 0 108px;
    //   display: flex;
    //   justify-content: space-between;
    //   .pItem{
    //     width: 480px;
    //     line-height: 30px;
    //     margin-bottom: 40px;
    //   }
    //   p{
    //     color: #31B2E4;
    //     line-height: 10px;
    //     margin: 0px 0px 10px 0px;
        
    //   }
    // }

    
  }


}

.content {
  position: relative;

  img {
    display: block;
    width: 100%;
    height: auto;
  }

}
.area {
  width: 100%;
  // height: 1080px;
  // background: pink;
  overflow: hidden;
  position: relative;

  .desc {
    width: 100%;
    color: #fff;
    margin-top: 200px;
    .title {
      font-size: 44px;
      font-family: SourceHanSansCN, SourceHanSansCN;
      font-weight: bold;
      text-align: center;
      width: 100%;

    }
    position: absolute;
    width: 100%;
    top:0px;
    left:0px;
    right: 0;
    bottom: 0;

  }


}

.content {
  .info{
    width: 820px;
    height: 84px;
    font-size: 16px;
    font-weight: 400;
    color: #000000;
    line-height: 28px;
    margin: 106px auto;
  }
  .swiperPagination{}
  .tip{
    margin: 43px auto;
    width: 876px;
    height: 155px;
    font-size: 24px;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 36px;
    text-align: center;
  }  
  .tip2{
    margin: 43px auto;
    width: 876px;
height: 155px;
font-size: 16px;
font-weight: 400;
color: #000000;
line-height: 28px;
  }
  .title2 {
    font-size: 44px;
    color: #000000;
    font-family: SourceHanSansCN, SourceHanSansCN;
    font-weight: bold;
    text-align: center;
    width: 100%;

  }
  .blue{
    color: #0052D9;
  }
  .bgImg{
    width: 100%;
    height: auto;

  }

  .mt2{
    margin-top: 152px;
  }
  .swWrap {
    width: 100%;
    // background: #0066ff;
    .container {
      width: 100%;
      margin: 0 auto;
      position: relative;
    }
    .swiperSlide{
      // position: relative;
      // left: -50%;
    }
    style.info{
      width: 820px;
      height: 84px;
      font-size: 16px;
      font-weight: 400;
      color: #000000;
      line-height: 28px;
    }
    img {
      width:95%;
      height: auto;
      display: block;
      margin: 0 auto;
    }
    .item {
      margin: 0 auto;
      margin-top: 24px;
      text-align: center;
    }
    p {
      margin: 0;
    }
    .ptitle {
      font-size: 40px;
      font-weight: 500;
      color: #000000;
      line-height: 47px;
    }
    .pdesc {
      font-size: 14px;
      font-weight: 500;
      color: #2e3033;
      line-height: 16px;
      margin-top: 16px;
    }
    .dot,
    .span2 {
      font-size: 0;
      i {
        display: inline-block;
        width: 8px;
        height: 8px;
        background: #dce0e6;
        border-radius: 50%;
        margin-right: 5px;
      }
    }
    .dot i:nth-child(1) {
      width: 28px;
      height: 8px;
      background: #0066ff;
      border-radius: 8px;
    }
    .span2 i:nth-child(1) {
      width: 28px;
      height: 8px;
      background: #0066ff;
      border-radius: 8px;
    }
  }
 
}
.area {
  width: 100%;
  // height: 1080px;
  // background: pink;
  overflow: hidden;
  position: relative;

  .desc {
    width: 100%;
    display: flex;
    color: #fff;
    margin-top: 200px;
    .title {
      font-size: 44px;
      font-family: SourceHanSansCN, SourceHanSansCN;
      font-weight: bold;
      text-align: center;
      width: 100%;

    }
    position: absolute;
    width: 100%;
    top:0px;
    left:0px;
    right: 0;
    bottom: 0;

  }


 

}

.content {
  position: relative;
  .grid{
    display: grid;
    grid-template-columns:repeat(2,642px);
    grid-gap:34px;

    // justify-content: space-around;
  }
  .flex1{
    display: flex;
    justify-content: space-around;
  }
  .box{
    margin: 104px auto;
    display: grid;  
    align-items: center;  
    justify-items: center;  
  }
  .lable{
    width: 334px;
height: 284px;
background: #F7F7F7;
font-size: 26px;
font-weight: 500;
text-align: center;
line-height: 284px;
  }
  .img{
    width: 308px;
    height: 284px;
  }
  .map{
    width: 80%;
    height: 500px;
    margin: 0 auto;
    margin-bottom: 70px;
  }
  img {
    display: block;
    width: 100%;
    height: auto;
  }
  .info{
    width: 80%;
    margin: 108px auto;
    font-size: 30px;
    font-family: SourceHanSansCN, SourceHanSansCN;
    font-weight: 500;
    color: #000000;
  }

}
.area {
  width: 100%;
  // height: 1080px;
  // background: pink;
  overflow: hidden;
  position: relative;

  .desc {
    width: 100%;
    display: flex;
    align-items: center;
    .title {
      font-size: 44px;
      font-family: SourceHanSansCN, SourceHanSansCN;
      font-weight: bold;
      text-align: center;
      width: 100%;

    }
  

  }

  .desc1 {
    position: absolute;
    width: 100%;
    top:0px;
    left:0px;
    right: 0;
    bottom: 0;
  }

 

}

.content {
  position: relative;
  .map{
    width: 80%;
    height: 500px;
    margin: 0 auto;
    margin-bottom: 70px;
  }
  img {
    display: block;
    width: 100%;
    height: auto;
  }
  .info{
    width: 80%;
    margin: 108px auto;
    font-size: 30px;
    font-family: SourceHanSansCN, SourceHanSansCN;
    font-weight: 500;
    color: #000000;
  }

}
.area {
  width: 100%;
  // height: 1080px;
  // background: pink;
  overflow: hidden;
  position: relative;

  .desc {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    margin-left: 150px;
    font-size: 44px;

    font-weight: bold;
    color: #FFFFFF;
    line-height: 66px;
  }

}

.area1 {
  width: 100%;
  // height: 1080px;
  // background: pink;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .cycleImg {
    width: 50%;
    height: auto;
    // float: left;
  }

  .text {

    width: 50%;

    font-size: 26px;
    text-align: left;

    .box {
      width: 350px;
      margin: 0 auto;
    }

    .title {
      font-size: 44px;
      color: #0B3AA9;
      font-weight: bold;
      margin-bottom: 38px;
    }

    .input {
      width: 344px;
      height: 49px;
      border-radius: 10px;
      border: 1px solid #979797;
      margin-bottom: 38px;
      text-indent: 10px;
    }

    .btn {
      width: 110px;
      text-align: center;
      height: 38px;
      background: #0B3AA9;
      font-size: 16px;
      font-weight: 500;
      color: #FFFFFF;
      line-height: 38px;
      cursor: pointer;
    }
  }
}

.content {
  position: relative;

  img {
    display: block;
    width: 100%;
    height: auto;
  }

}
.footer {
  // position: absolute;
  // left: 0;
  // right: 0;
  // bottom: 48px;
  // text-align: center;
  // z-index: 10;
  height: 56px;
  line-height: 56px;
  background: #333333;
  font-size: 16px;
  color: #fff;
  font-weight: 400;
  display: flex;
  justify-content:space-between;
  width: 100%;
  .item{
    text-align: center;
    width: 33.3%;
    font-weight: 500;
  }

}
.copyright{
  width: 100%;
  height: 20px;
  line-height: 20px;
  font-size: 12px;
  text-align: center;
  color: #fff;
  background: #333333;
}


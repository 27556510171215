@font-face {
 font-family: 'siyuan';
 src:url('./static/font/SourceHanSansCN-Normal.otf') format('truetype');
 font-weight: normal;
 font-style: normal;
}
body{
    font-family: siyuan;
}
a{
    text-decoration: none;
}
.main{
    margin-top: 64px;
}
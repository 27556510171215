.header {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: 64px;
  text-align: center;
  padding: 0 58px;
  color: #000;
  background: #fff;
  z-index: 10000s;
  .logo {
    position: absolute;
    height: 31px;
    top: 17px;


    font-weight: 400;
    line-height: 28px;
    z-index: 100;
    display: flex;
    align-items: center;

    font-size: 28px;
    font-family: iFontszhounianti;
    color: #0B3AA9;
    line-height: 34px;

    // -webkit-background-clip: text;
    // -webkit-text-fill-color: transparent;
  }
  .nav {
    position: relative;
    display: inline-flex;
    gap: 20px;
    height: 100%;
    padding: 20px 0;
    align-items: center;
    z-index: 10000;
    .navItem {
      font-size: 16px;
      line-height: 1;
      font-weight: 500;
      cursor: pointer;
      padding: 0 16px;
      // &:hover {
      //   display: flex;
      //   align-items: center;
      //   background: #f2f2f2;
      //   border-radius: 4px;
      //   padding: 6px 16px;
      // }
    }
    .on {
      // display: flex;
      // align-items: center;
      // background: #f2f2f2;
      // border-radius: 4px;
      // padding: 6px 16px;
      color: #0B3AA9;
    }
  }
  .wrap {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    padding-top: 112px;
    z-index: 1000;
    background: #fff;
    height: 450px;
    display: none;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
    .lists {
      padding-left: 450px;
      // display: inline-flex;
      // gap: 100px;

      .group {
        text-align: left;
        font-size: 16px;
        font-family: Source Han Sans CN, Source Han Sans CN;
        font-weight: 500;
        color: #2e3033;

        .title {
          font-size: 14px;
          font-family: Source Han Sans CN, Source Han Sans CN;
          font-weight: 400;
          color: #5c6066;
          line-height: 16px;
          margin-bottom: 32px;
        }
        .img {
          width: 120px;
          height: 120px;
          margin-bottom: 10px;
        }
        .center {
          text-align: center;
        }
        .products {
          display: grid;
          flex-direction: column;
          gap: 16px 24px;
          grid-auto-flow: column;
          grid-template-rows: repeat(5, auto);
          .i span {
            cursor: pointer;
            position: relative;
            &:hover {
              text-decoration: underline;
              text-underline-offset: 4px;
            }
          }
        }
      }
    }
  }
}

.active {
  color: #000;
  .navItem {
    color: #2e3033;
  }
  .wrap {
    display: block;
  }
}

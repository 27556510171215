.area {
  width: 100%;
  // height: 1080px;
  // background: pink;
  position: relative;
  overflow: hidden;
  .video{

    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
  }
  .desc{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
    display: flex;
    align-items: center;

    color: #FFFFFF;
    line-height: 66px;
    .title{
      font-size: 44px;
      margin-bottom: 57px;
      font-weight: bold;
    }
    .label{
      text-align: center;
      width: 700px;
      margin: 0 auto;
      font-size: 24px;
      font-family: SourceHanSansCN, SourceHanSansCN;
      color: #FFFFFF;
      line-height: 47px;
    }
  }

}
.area1{
  width: 100%;
  // height: 1080px;
  // background: pink;
  display: flex;
  justify-content: space-between;
  &.alCenter{
  align-items: center;

  }
  .cycleImg{
    width: 60%;
    height: auto;
    text-align: center;
    // float: left;
    .centerImg{
      width: 100%;
      padding: 50px;
      text-align: center;
    }
    
  }
  .text{
    text-align: center;
   
    width: 40%;
    box-sizing: border-box;
    padding:0 100px;

    font-size: 26px;
    text-align: left;

    &.two{
      flex-shrink: 0; /* 防止容器缩小 */  
      flex-grow: 1;
      align-items: center;
      display: flex;
      background: linear-gradient(180deg, rgba(0,28,60,0.93) 0%, #000C1C 100%);
      
      &.color{
        background: linear-gradient(180deg, rgba(31,63,112,0.81) 0%, #1F3E6F 100%);
      }
      .center{
      }
      .title{
      
        color: #fff;
      }
      .label{
        color: #fff;
      }
    }
    .title{
      font-size: 16px;
      font-weight: 500;
      color: #0B3AA9;
      margin-bottom: 30px;
    }
    .label{
      font-size: 14px;
      color: #000;
    }
  }
}
.area2{
  width: 100%;
  // height: 1080px;
  // background: pink;
  display: flex;
  justify-content: space-between;
  &.alCenter{
  align-items: center;

  }
  .cycleImg{
    width: 65%;
    height: auto;
    text-align: center;
    // float: left;
    .centerImg{
      width: 90%;
      display: block;
      margin: 0 auto;
      padding: 80px;
      text-align: center;
    }
    
  }
  .text{
    text-align: center;
   
    width: 35%;
    box-sizing: border-box;
    padding:0 80px;

    font-size: 26px;
    text-align: left;
    &.two{
      flex-shrink: 0; /* 防止容器缩小 */  
      flex-grow: 1;
      align-items: center;
      display: flex;
      background: linear-gradient(180deg, rgba(0,28,60,0.93) 0%, #000C1C 100%);
      .center{
      }
      .title{
      
        color: #fff;
      }
      .label{
        color: #fff;
      }
    }
    .title{
      font-size: 16px;
      font-weight: 500;
      color: #0B3AA9;
      margin-bottom: 30px;
    }
    .label{
      font-size: 14px;
      color: #000;
    }
  }
}
.content {
  position: relative;

  img {
    display: block;
    width: 100%;
    height: auto;
  }

}
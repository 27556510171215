.area {
  width: 100%;
  // height: 1080px;
  // background: pink;
  overflow: hidden;
  position: relative;



}

.content {
  position: relative;
  text-align: center;
  img {
    display: block;
    margin: 50px auto;
    width: 80%;
    height: auto;
  }

}
.area {
  width: 100%;
  // height: 1080px;
  // background: pink;
  overflow: hidden;
  position: relative;

  .desc1 {
    width: 1400px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;

    .item {
      width: 324px;
      height: 529px;
  
      background: url('//homepage-life-decode.oss-cn-hangzhou.aliyuncs.com/c_connect/img/hxgn-a-2@2x.png') no-repeat;
      background-size: 100% 100%;
      &.v2{
        background: url('//homepage-life-decode.oss-cn-hangzhou.aliyuncs.com/c_connect/img/hxgn-a-3@2x.png') no-repeat;
        background-size: 100% 100%;
      }
      &.v3{
        background: url('//homepage-life-decode.oss-cn-hangzhou.aliyuncs.com/c_connect/img/hxgn-a-4@2x.png') no-repeat;
        background-size: 100% 100%;
      }
      &.v4{
        background: url('//homepage-life-decode.oss-cn-hangzhou.aliyuncs.com/c_connect/img/hxgn-a-5@2x.png') no-repeat;
        background-size: 100% 1
      }

    }
  }

  .topTitle {
    font-size: 44px;
    font-family: SourceHanSansCN, SourceHanSansCN;
    font-weight: bold;
    color: #000000;
    line-height: 66px;
    text-align: center;
    margin: 100px 0;
  }

  .desc {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    display: flex;
    align-items: center;

    text-align: center;

    .center {
      width: 450px;
      text-align: left;
      margin-left: 200px;
      font-size: 16px;
      font-weight: 500;

      .title {
        margin-bottom: 120px;
      }

      .l {
        margin-top: 20px;
      }

      .c {
        color: #31B2E4;

      }

      color: #fff;

    }

    font-weight: 400;
    color: #FFFFFF;
    line-height: 30px;

    .title {
      font-size: 44px;
      font-weight: bold;
      margin-bottom: 40px;
    }

    .btn {
      width: 108px;
      height: 40px;
      background: #FFFFFF;
      color: #000;
      font-size: 16px;
      margin: 0 auto;
      margin-top: 40px;

      line-height: 40px;
    }

    .item {}
  }

  .desc1 {
    margin-bottom: 140px;
    text-align: center;
    color: #fff;
    .name{
      font-size: 26px;
      margin-bottom:30px;
      padding-top: 100px;
    }
    .info{
      height: 200px;
    }
    p{
      font-size: 16px;
      line-height: 30px;
      margin: 0;
    }
    .btn{
      width: 146px;
height: 40px;
line-height: 40px;
background: #FFFFFF;
font-size: 16px;
font-weight: 500;
color: #000000;
text-align: center;
margin: 0 auto;
    }
  }

}

.content {
  position: relative;

  img {
    display: block;
    width: 96%;
    margin: 30px auto;
    border-radius: 30px;
    height: auto;
  }

}